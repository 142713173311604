<template lang="">
  <div>
    <v-form ref="form">
      <div class="row mt-2">
        <input-text
          v-model="form.codigoInstrumento"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.instrumento')"
          disabled
        />
        <input-text
          v-model="form.nomeTipoInstrumento"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.tipo_instrumento')"
          disabled
        />
        <input-text
          v-model="form.codigoOrdemServico"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.ordem_servico')"
          disabled
        />
      </div>
      <div class="row">
        <input-text
          v-model="form.flagSemConcerto"
          class="col-12 col-md-3"
          :label="
            $t(
              'modulos.operacao.formulario.possivel_executar_concerto_instrumento'
            )
          "
          disabled
        />
        <input-text
          v-model="form.localExecucaoNome"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.local_execucao')"
          disabled
        />
        <input-text
          v-model="form.tipoPrazoEntrega"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.tipo_prazo_entrega')"
          disabled
        />
        <input-text
          v-model="form.prazoEntregaEmDias"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.prazo_entrega')"
          type="number"
          disabled
        />
      </div>
      <div class="row">
        <input-text
          v-model="form.flagGarantia"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.garantia')"
          disabled
        />
        <input-text
          v-model="form.justificativaGarantia"
          class="col-12 col-md-9"
          :label="$t('modulos.operacao.formulario.justificativa_garantia')"
          disabled
        />
      </div>
      <accordion-padrao
        class="col-12"
        corAzulTexto
        alterarCor
        :titulo="$t('modulos.operacao.steps.calibracao')"
      >
        <div class="row" v-if="form.calibracaoDados?.length">
          <input-text
            v-model="form.tipoCalibracaoNome"
            class="col-md-4"
            :label="$t('modulos.operacao.formulario.tipo_calibracao')"
            disabled
          />
          <input-text
            v-model="form.pontosCalibracaoInstrumento"
            :label="
              $t('modulos.operacao.formulario.pontos_calibracao_instrumento')
            "
            class="col-md-3"
            type="number"
            disabled
          />
          <input-text
            v-model="form.pontosExtras"
            :label="$t('modulos.operacao.formulario.pontos_extras')"
            class="col-md-3"
            type="number"
            disabled
          />
        </div>
        <data-table
          :value="form.calibracaoDados"
          v-if="form.calibracaoDados?.length"
          show-gridlines
          :paginator="false"
          class="p-datatable-sm mt-2 tabela-prime-vue-inherit"
          :rows="50"
          :scrollable="true"
          scroll-height="500px"
          data-key="id"
          :row-hover="true"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[10, 25, 50]"
          current-page-report-template="Mostrando {first} a {last} de {totalRecords} itens"
          responsive-layout="scroll"
        >
          <Column
            field="codigo"
            :header="$t('modulos.operacao.tabela.codigo')"
          />
          <Column field="faixa" :header="$t('modulos.operacao.tabela.faixa')">
          </Column>
          <Column
            field="tempoExecucao"
            :header="$t('modulos.operacao.tabela.tempo_execucao')"
          />
          <Column
            field="minimoPontosServico"
            :header="$t('modulos.operacao.tabela.minimo_pontos')"
          />
          <Column
            field="codigoContrato"
            :header="$t('modulos.operacao.tabela.codigo_contrato')"
          />
        </data-table>
      </accordion-padrao>
      <accordion-padrao
        class="col-12"
        corAzulTexto
        alterarCor
        :titulo="$t('modulos.operacao.steps.manutencao')"
      >
        <data-table
          :value="form.manutencoes"
          v-if="form.manutencoes?.length"
          show-gridlines
          :paginator="false"
          class="p-datatable-sm tabela-prime-vue-inherit"
          :rows="50"
          :scrollable="true"
          scroll-height="500px"
          data-key="id"
          :row-hover="true"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[10, 25, 50]"
          current-page-report-template="Mostrando {first} a {last} de {totalRecords} itens"
          responsive-layout="scroll"
        >
          <Column
            field="codigo"
            :header="$t('modulos.operacao.tabela.codigo')"
          />
          <Column field="nome" :header="$t('modulos.operacao.tabela.nome')" />
          <Column field="faixa" :header="$t('modulos.operacao.tabela.faixa')">
          </Column>
          <Column
            field="tempoExecucaoPadrao"
            :header="$t('modulos.operacao.tabela.horas_execucao_padrao')"
          />
          <Column
            field="tempoExecucao"
            :header="$t('modulos.operacao.tabela.horas_execucao')"
          />
          <Column
            field="contrato"
            :header="$t('modulos.operacao.tabela.codigo_contrato')"
          />
        </data-table>
      </accordion-padrao>
      <accordion-padrao
        class="col-12"
        corAzulTexto
        alterarCor
        :titulo="$t('modulos.operacao.steps.pecas')"
      >
        <data-table
          :value="form.pecas"
          v-if="form.pecas?.length"
          show-gridlines
          :paginator="false"
          class="p-datatable-sm tabela-prime-vue-inherit"
          :rows="50"
          :scrollable="true"
          scroll-height="500px"
          data-key="id"
          :row-hover="true"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[10, 25, 50]"
          current-page-report-template="Mostrando {first} a {last} de {totalRecords} itens"
          responsive-layout="scroll"
        >
          <Column
            field="codigo"
            :header="$t('modulos.operacao.tabela.codigo')"
          />
          <Column field="nome" :header="$t('modulos.operacao.tabela.nome')" />
          <Column
            field="fabricante"
            :header="$t('modulos.operacao.tabela.fabricante')"
          />
          <Column
            field="quantidadeEstoque"
            :header="$t('modulos.operacao.tabela.quantidade_estoque')"
          />
          <Column
            field="prazoEntrega"
            :header="$t('modulos.operacao.tabela.prazo_entrega')"
          />
          <Column
            field="tempoExecucao"
            :header="$t('modulos.operacao.tabela.horas_execucao')"
          />
          <Column
            field="quantidadeOrcada"
            :header="$t('modulos.operacao.tabela.quantidade_orcada')"
          />
        </data-table>
      </accordion-padrao>
    </v-form>
  </div>
</template>
<script>
export default {
  props: {
    form: { type: Object, default: () => ({}) },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
</style>
