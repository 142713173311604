<template>
  <div>
    <div class="row d-flex mb-8">
      <div class="titulo-pagina col-12 col-md-7"></div>
      <div class="titulo-pagina col-12 col-md-5">
        <div class="d-flex" style="place-content: space-between">
          <div>{{ $t('modulos.operacao.formulario.valor_total') }}</div>
          <div style="color: #039855">{{ progressBarInformation() }}</div>
        </div>
        <v-progress-linear
          :value="percentualProgressBar()"
          height="20"
          background-color="#E4E7EC"
          color="#039855"
        />
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.operacao.formulario.abas.analise_tecnica') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.operacao.formulario.abas.analise_tecnica_anterior') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <AnaliseTecnicaAtualVisualizacao
          :form="form"
        ></AnaliseTecnicaAtualVisualizacao>
      </v-tab-item>
      <v-tab-item>
        <AnaliseTecnicaAnteriorVisualizacao
          :form="formAnterior"
        ></AnaliseTecnicaAnteriorVisualizacao>
      </v-tab-item>
    </v-tabs-items>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <v-btn
        :depressed="true"
        :outlined="false"
        class="botao-reprovar mr-2"
        @click="reprovar"
      >
        {{ $t('geral.botoes.reprovar') }}
      </v-btn>
      <botao-padrao @click="aprovar">
        {{ $t('geral.botoes.aprovar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import AnaliseTecnicaService from '@common/services/cadastros/AnaliseTecnicaService';
import AnaliseTecnicaAtualVisualizacao from './AnaliseTecnicaAtualVisualizacao';
import AnaliseTecnicaAnteriorVisualizacao from './AnaliseTecnicaAnteriorVisualizacao';
import OmieService from '@common/services/cadastros/OmieService';
export default {
  components: {
    AnaliseTecnicaAtualVisualizacao,
    AnaliseTecnicaAnteriorVisualizacao,
  },
  props: ['id'],
  data() {
    return {
      form: {},
      formAnterior: {},
      valorTotal: 0,
      codigoString: '',
      indiceAba: 0,
    };
  },
  watch: {},
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.operacao.titulos.analise_tecnica')
    );
    this.buscaDadosAnaliseTecnica(this.id);
  },
  methods: {
    buscaDadosAnaliseTecnica(analiseTecnicaId) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      AnaliseTecnicaService.visualizar(analiseTecnicaId)
        .then((res) => {
          this.montaFormularioAnaliseTecnicaAtual(res);
          if (res.data?.analiseTecnicaAnterior) {
            this.montaFormularioAnaliseTecnicaAnterior(
              res.data.analiseTecnicaAnterior
            );
          }

          this.codigoString = '';
          if (res.data.pecas.length) {
            res.data.pecas.map((item) => {
              this.codigoString += `&Codigos=${item.codigo}`;
            });
          }

          this.montaListagemPecas(this.codigoString);
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    montaListagemPecas(codigoString) {
      if (codigoString) {
        let parametros = {
          flagFiltrarCodigos: true,
        };
        this.$store.dispatch('Layout/iniciarCarregamento');
        OmieService.buscaDadosOmie(codigoString, parametros)
          .then((res) => {
            this.form.pecas.map((item) => {
              if (res.data.produtos.length) {
                res.data.produtos.map((itemProduto) => {
                  if (item.codigo === itemProduto.codigo)
                    item.quantidadeEstoque = itemProduto.saldo;
                });
              }
            });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
    montaFormularioAnaliseTecnicaAtual(res) {
      this.form = res.data;
      this.form.calibracaoDados = [];
      this.form.calibracaoDados.push(res.data.calibracao);

      this.form.codigoInstrumento = res.data.instrumento?.codigo;
      this.form.nomeTipoInstrumento = res.data.instrumento?.tipoInstrumento;
      this.form.codigoOrdemServico = res.data.ordemServico?.codigo;

      this.form.localExecucaoNome = res.data.localExecucao?.nome;
      this.form.tipoCalibracaoNome = res.data.calibracao?.tipoCalibracao?.nome;

      this.form.pontosCalibracaoInstrumento =
        res.data.calibracao?.quantidadePontos;

      if (
        res.data.calibracao?.minimoPontosServico >
        this.form.pontosCalibracaoInstrumento
      ) {
        this.form.pontosExtras = 0;
      } else {
        this.form.pontosExtras =
          this.form.pontosCalibracaoInstrumento -
          res.data.calibracao?.minimoPontosServico;
      }

      if (!res.data.flagSemConcerto) this.form.flagSemConcerto = 'Sim';
      else this.form.flagSemConcerto = 'Não';
      if (res.data.flagGarantia) this.form.flagGarantia = 'Sim';
      else this.form.flagGarantia = 'Não';

      this.valorTotal = res.data.valorTotal ? res.data.valorTotal : 0;

      this.percentualProgressBar();
      this.progressBarInformation();
    },
    montaFormularioAnaliseTecnicaAnterior(res) {
      this.formAnterior = res;
      this.formAnterior.calibracaoDados = [];
      this.formAnterior.calibracaoDados.push(res.calibracao);

      this.formAnterior.codigoInstrumento = res.instrumento?.codigo;
      this.formAnterior.nomeTipoInstrumento = res.instrumento?.tipoInstrumento;
      this.formAnterior.codigoOrdemServico = res.ordemServico?.codigo;

      this.formAnterior.localExecucaoNome = res.localExecucao?.nome;
      this.formAnterior.tipoCalibracaoNome =
        res.calibracao?.tipoCalibracao?.nome;

      this.formAnterior.pontosCalibracaoInstrumento =
        res.calibracao?.quantidadePontos;

      if (
        res.calibracao?.minimoPontosServico >
        this.formAnterior.pontosCalibracaoInstrumento
      ) {
        this.formAnterior.pontosExtras = 0;
      } else {
        this.formAnterior.pontosExtras =
          this.formAnterior.pontosCalibracaoInstrumento -
          res.calibracao?.minimoPontosServico;
      }

      if (!res.flagSemConcerto) this.formAnterior.flagSemConcerto = 'Sim';
      else this.formAnterior.flagSemConcerto = 'Não';
      if (res.flagGarantia) this.formAnterior.flagGarantia = 'Sim';
      else this.formAnterior.flagGarantia = 'Não';
    },
    percentualProgressBar() {
      let percentualTotal = 0;
      if (this.valorTotal) {
        percentualTotal = Number(
          (this.valorTotal / this.instrumentoValor) * 100
        ).toFixed(3);
      } else this.percentualTotal = 0;

      return Math.trunc(percentualTotal);
    },
    progressBarInformation() {
      if (!this.instrumentoValor) {
        return 'Instrumento sem valor';
      }
      let percentualTotal = 0;
      if (this.valorTotal) {
        percentualTotal = Number(
          (this.valorTotal / this.instrumentoValor) * 100
        ).toFixed(3);
      } else this.percentualTotal = 0;

      let information = `${Math.trunc(percentualTotal)}%`;

      return information;
    },
    reprovar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      AnaliseTecnicaService.reprovarGarantia(this.id)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.analise_tecnica.salvo_com_sucesso`)
          );
          this.$router.push({ name: 'operacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    aprovar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      AnaliseTecnicaService.aprovarGarantia(this.id)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.analise_tecnica.salvo_com_sucesso`)
          );
          this.$router.push({ name: 'operacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'operacao' });
      });
    },
  },
};
</script>
